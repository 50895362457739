import * as React from "react";
import { Box } from "@mui/material";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import background from "./bg_gen.png";
import GoogleButton from "react-google-button";
import { Title } from "./Headers";

export default function Login({ setUser }) {
  const handleSubmit = (event) => {
    event.preventDefault();

    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    const auth = getAuth();
    auth.languageCode = "it";
    provider.setCustomParameters({
      login_hint: "user@example.com",
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${background})`,
        backgroundSize: "100% 100%",
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        style={{
          width: "680px",
          maxWidth: "100%",
          background: "#fff",
          borderRadius: "10px",
          padding: "5em 0",
          margin: "10em auto ",
        }}
      >
        <Title>Login</Title>
        <GoogleButton onClick={handleSubmit} />
      </Box>
    </div>
  );
}
