import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";
import { useState } from "react";

function Router() {
  const [user, setUser] = useState();
  return (
    <BrowserRouter>
      <Routes>
        {!user && (
          <Route index path="/" element={<Login setUser={setUser} />} />
        )}
        {user && <Route index path="/" element={<Dashboard user={user} />} />}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
