import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Chip,
  Typography,
} from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function PWNEDCard({ pwned }) {
  const [expanded, setExpanded] = React.useState(false);

  const { DataClasses, BreachDate, Description, Title, LogoPath } = pwned;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      style={{ margin: "1em 0", padding: ".2em", backgroundColor: "#dddddd" }}
    >
      <CardHeader title={Title} subheader={BreachDate} />
      <CardMedia
        component="img"
        height="50"
        image={LogoPath}
        alt={Title}
        sx={{ objectFit: "contain" }}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary"></Typography>
      </CardContent>
      <CardActions>
        <div>
          {DataClasses.map((dataClass) => {
            return (
              <Chip
                key={Title + dataClass}
                style={{ margin: "0 .2em" }}
                label={dataClass}
                color="primary"
                variant="outlined"
              />
            );
          })}
        </div>

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Description:</Typography>
          <Typography paragraph>
            <div dangerouslySetInnerHTML={{ __html: Description }}></div>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
