import { styled } from "@mui/material";

export const Title = styled("p")({
  width: "100%",
  display: "block",
  fontFamily: "Montserrat-Medium",
  fontSize: "39px",
  color: "#555555",
  lineHeight: "1.2",
  textAlign: "center",
});
