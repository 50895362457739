import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAIo3gp3oSYD7g_1XaeU7LZ6xa9bhrtBSs",
  authDomain: "identityprotection-6f364.firebaseapp.com",
  projectId: "identityprotection-6f364",
  storageBucket: "identityprotection-6f364.appspot.com",
  messagingSenderId: "376210405962",
  appId: "1:376210405962:web:f77230b958e7f7a7b8c4b3",
  measurementId: "G-S8ZWJSK5S1",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const db = getFirestore(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
