import {
  Grid,
  IconButton,
  styled,
  Avatar,
  ListItemAvatar,
  List,
  ListItemText,
  ListItem,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Mail";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  collection,
  doc,
  addDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./index";
import { useEffect, useState } from "react";
import PWNEDCard from "./PWNEDCard";
import { Title } from "./Headers";

const Container = styled("div")({
  fontWeight: "300",
  fontSize: "38px",
  margin: "20px auto",
  lineHeight: "120%",
  textAlign: "center",
  width: "700px",
  maxWidth: "100%",
  background: "#cbcbcb",
  borderRadius: "10px",
  padding: "20px",
});

function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}
const addMail = async (uid, mail, setMails) => {
  try {
    const mailCollection = await collection(db, "users", uid, "mails");
    await addDoc(mailCollection, { mail, updated: "" });
    await loadMails(uid, setMails);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const loadMails = async (uid, setMails) => {
  const mailCollection = await collection(db, "users", uid, "mails");

  await getDocs(mailCollection).then((querySnapshot) => {
    const newData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setMails(newData);
  });
};

const removeMail = async (uid, id, setMails) => {
  const mailDocument = await doc(db, "users", uid, "mails", id);
  await deleteDoc(mailDocument);
  await loadMails(uid, setMails);
};

export default function Dashboard({ user }) {
  const { uid } = user;

  const [newMail, setNewMail] = useState([]);
  const [mails, setMails] = useState([]);

  useEffect(() => {
    loadMails(uid, setMails);
  }, [uid, setMails]);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Title>Set up monitoring</Title>
        </Grid>
        <Grid item xs={8}>
          <TextField
            value={newMail}
            onChange={(e) => setNewMail(e.target.value)}
            fullWidth
            size="small"
          ></TextField>
        </Grid>
        <Grid item xs={4}>
          <Button
            disabled={!newMail || !isValidEmail(newMail)}
            fullWidth
            onClick={() => {
              addMail(uid, newMail, setMails);
            }}
            variant="contained"
          >
            Add new email
          </Button>
        </Grid>
        <Grid item xs={12}>
          <List dense>
            {mails.map((mailInformation) => (
              <>
                <ListItem
                  key={mailInformation.id}
                  secondaryAction={
                    <IconButton
                      onClick={() => {
                        removeMail(uid, mailInformation.id, setMails);
                      }}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={mailInformation.mail} />
                </ListItem>
                {!mailInformation?.updated ? (
                  <Typography paragraph>
                    This mail has not yet been checked. Please check back later
                  </Typography>
                ) : null}
                {mailInformation?.updated && !mailInformation?.pwned ? (
                  <Typography paragraph>This email was not stolen</Typography>
                ) : null}
                {mailInformation?.pwned?.map((pwned, i) => {
                  return (
                    <PWNEDCard
                      key={mailInformation.id + "pwned" + i}
                      pwned={pwned}
                    />
                  );
                })}
              </>
            ))}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
}
